.select2-selection--single {
    box-sizing: border-box;

    cursor: pointer;
    display: block;

    height: 57px;

    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
        display: block;
        padding-left: 25px;
        padding-right: 60px;
        font-size: 14px;
        font-weight: $default-font-weight-semibold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .select2-selection__clear {
        position: relative;
    }
}

&[dir="rtl"] {
    .select2-selection--single {
        .select2-selection__rendered {
            padding-right: 18px;
            padding-left: 30px;
        }
    }
}
