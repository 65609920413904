.wysiwyg {
	img {
	    height: auto !important;
	    max-width: 100% !important;
	}

	.img {
		margin: $default-margin;
		text-align: center;

		img {
			@include max-screen($xs-min) {
				width: auto !important;
			}
		}
        
        &--left {
			float: left;
			margin: 0 30px 15px 0 !important;
		}

		&--right {
			float: right;
			margin: 0 0 15px 30px !important;
		}
        
    	&--left,
		&--right {
			max-width: 45%;

			img {
				display: block;
			    width: 100%;

			    @include max-screen($xs-min) {
			    	display: inline-block !important;
				    width: auto !important;
		    	}
			}

			@include max-screen($xs-min) {
				max-width: 100% !important;
				float: none !important;
				margin: 0 0 25px !important;
				text-align: center !important;
			}
		}
    }
}