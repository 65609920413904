.image-container {
  position: relative;
  height: 551px;
  margin-bottom: 15px;
  z-index: 1;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%);
    z-index: 1;
  }

  @include max-screen($md-max) {
    height: auto;
  }

  img {
    height: inherit;
    width: 100%;
    object-fit: cover;

    @include max-screen($md-max) {
      object-fit: scale-down;
    }
  }

  .image-name {
    position: absolute;
    bottom: 24px;
    left: 24px;
    font-size: 14px;
    color: $white-color;
    font-weight: $default-font-weight;
    opacity: 1;
    z-index: 2;
  }
}


.image-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-screen($lg-max) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @include max-screen($md-max) {
    justify-content: space-between;
    margin-bottom: 15px;
  }
  @include max-screen($xs-max) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  &__item {
    padding: 12px 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-size: 13px;
    color: $black-color;

    @include max-screen($lg-max) {
      margin-bottom: 8px;
      width: 215px;
    }

    @include max-screen($xs-max) {
      margin-bottom: 0;
      padding: 12px;
      width: initial;


      span {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;

      @include max-screen($xs-max) {
        margin-right: 12px;
      }
    }

    i {
      margin-right: 8px;

      @include max-screen($xs-max) {
        margin-right: 0;
        font-size: 18px;
      }
    }

  }
}

.image-bottom-info {
  font-size: 11px;
  color: $black-color;
}

.image-info-box {
  width: 100%;
  height: 551px;
  background-color: $white-color;
  margin-bottom: 15px;
  padding: 38px 51px;

  @include max-screen($lg-max) {
    padding: 38px;
  }

  @include max-screen($md-max) {
    height: auto;
  }

  @include max-screen($xxs-max) {
    padding: 25px;
  }

  .materials__download {
    padding: 30px 0;

    > a {
      color: #364A59;
      margin-bottom: 0;
    }
  }

  .materials__info {
    @include max-screen($xs-max) {
      margin-bottom: 0;
    }
  }

  .payment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 25px;
    color: $default-font-color;
    letter-spacing: -1.39px;
    font-weight: $default-font-weight-semibold;


    @include max-screen($xxs-max) {
      font-size: 23px;
      white-space: nowrap;
    }

    &__name {
      margin-right: 5px;
      white-space: nowrap;
    }

    &__amount {

    }
  }

  .buttons-wrapper {
    margin-top: 38px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include max-screen($xxs-max) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

    }

    a {
      white-space: nowrap;
      width: 100%;
      justify-content: center;
    }

    a:first-child {
      margin-right: 14px;

      @include max-screen($xxs-max) {
        margin-right: 0;
        margin-bottom: 14px;
      }
    }
  }
}

.hidden-box-wrapper {
  position: relative;
}

.hidden-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 352px;
  border: 1px solid $key-color-7;
  background-color: $key-color-8;
  z-index: 801;

  &__inner {
    position: relative;
    padding: 40px 64px 35px 64px;
  }

  &__close {
    position: absolute;
    top: 20px;
    left: 30px;
    color: $black-color;
  }

  &__title {
    font-size: 16px;
    color: $black-color;
    font-weight: $default-font-weight-semibold;
    margin-bottom: 18px;
  }
}


.js-hidden-info {
  display: block;
  @include max-screen($sm-max) {
      display: none;
  }
}

.js-open-hidden-info {
  display: block;

  @include max-screen($sm-max) {
      margin-top: 20px;
      cursor: pointer !important;
  }
}


.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .login-button {
    margin-right: 0;
  }
  
 

  &--desktop {
    display: flex;

    @include max-screen($xs-max) {
      display: none;
    }
  }

  &--mobile {
    display: none;

    .login-ask {
      margin-right: 20px;
    }

    @include max-screen($xs-max) {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

