.top {
	background-color: $key-color-3;
	padding: 23px 0 26px 0;
	position: relative !important;
	top: 0;
	width: 100%;
	z-index: 2;
	box-shadow: 0 2px 15px rgba(0, 0, 0, 0);
	@include transition();
    
    @include max-screen($md-max) {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }


	html.pz & {
		padding: 47px 0 23px;
	}

	html.main-page & {
		padding: 53px 0 59px;

		@include max-screen($md-max) {
		    padding: 30px 0 !important;
		}
	}


	&__logo {
		max-width: 150px;
		@include transition();

		html.main-page & {
			max-width: 192px;
		}
        
        @include max-screen($md-max) {
			margin-right: 20px;
            max-width: 115px !important;

			html.main-page & {
				max-width: 192px !important;
				margin: 0 auto;
			}

			.is-menu-open & {
				position: absolute;
				z-index: 99999;
			}
        }

		a {
			@include hover() {
				img {
					opacity: 0.5;
				}
			}
		}

		img {
			@include transition();
			width: 100%;
		}
	}

	&__text {
		font-size: 19px;
		color: #333333;
		font-style: italic;
		max-width: 305px;
		margin-left: 0;
		margin-right: auto;
		min-width: 330px;

		html.main-page & {
			font-size: 20px;
		}

		&--last {
			margin-left: auto;
			margin-right: 0;
			max-width: 356px;
			text-align: center;
		}

		@include max-screen($md-max) {
			html.main-page & {
				display: none;
			}
		}


		@include max-screen($sm-max) {
		    display: none;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.btn {
			min-width: 216px;
			margin-left: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@include max-screen($sm-max) {
		    display: none;
		}
	}

	&__nav {
        @include max-screen($md-max) {
            display: block !important;
            background-color: rgba(#F1F5F8, .98);
			margin: $clear-margin;
            padding: $clear-padding;
			opacity: 0;
			visibility: hidden;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 900;
			@include transition();
            
            .is-menu-open & {
                overflow-y: auto;
                opacity: 1;
                visibility: visible;
            }
        }
	}
}

