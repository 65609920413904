.footer {
  background-color: #fff;
  font-size: 13px;
  padding: 40px 0;
  color: #252525;

  .logo-wrapper {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__central-logo {
    display: flex;
    margin: 0 auto;


    img {
      max-width: 97px;
      object-fit: scale-down;

      @include max-screen($xs-max) {
        max-width: 50px;
      }
    }


  }

  .upper-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    @include max-screen($sm-max) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .contact-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      cursor: pointer;

      &:hover {
        color: #537289;
      }
    }

    @include max-screen($sm-max) {
      margin-top: 39px;
    }
  }

  .bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-screen($sm-max) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__contact-link {
    display: flex;
    margin-left: 13px;
    font-size: 13px;
    font-weight: $default-font-weight-bold;
    color: #252525;
    white-space: nowrap;
  }

  &__social-link {
    color: #8E8E8E;
    font-size: 31px;
    margin-left: 18px;
  }

  &__nav {
    span {
      cursor: pointer;

      &:hover {
        color: #537289;
      }
    }

    @include max-screen($sm-max) {
      margin-bottom: 10px;
    }
  }

  &__nav-link {
    font-size: 13px;
    font-weight: $default-font-weight-bold;
    color: #252525;
    margin-left: 10px;

    @include max-screen($sm-max) {
      display: block;
      margin-right: 10px;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 0;
  }

  &__copy {
    margin-right: 30px;
    font-size: 13px;
    color: #252525;
    font-weight: 400;
  }
}
