.accordion {
    $this: &;
    font-size: 16px;

    &--alt {
        #{$this}__item {
            width: 100%;

            i {
                top: 0;
                font-size: 16px;
            }
        }

        #{$this}__title {
            width: 100%;
            border: 2px solid $black-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: inherit;
            font-size: 14px;
            padding: 0 30px;
            line-height: 55px;
            text-transform: inherit;
            text-decoration: inherit;
        }

        #{$this}__content-inner {
            padding: 50px 0;
        }
    }

    &__item {
        border: none;
        //border-bottom: 1px solid #E5E5E5;
        margin-bottom: 27px;
        position: relative;
        @include transition();

        @include max-screen($sm-max) {
            margin-bottom: 20px;
        }

        i {
            position: relative;
            top: 23px;
            //right: 20px;
            margin-left: 25px;
            font-size: 20px;
            color: $key-color-1;
            @include transform(rotate(0deg));
            @include transition();

            @include max-screen($sm-max) {
                top: 10px
            }

            @include max-screen($xxs-max) {
                font-size: 15px;
                top: 6px;
            }

        }


        &.is-active {


            i {
                @include transform(rotate(180deg));
                color: $key-color-1;
            }

            #{$this}__title-more {
                color: $key-color-1
            }
        }
    }

    &__title {
        @extend .flex, .flex--center-x, .flex--nowrap;
        justify-content: flex-start;
        cursor: pointer;
        line-height: 58px;
        font-weight: $default-font-weight-bold;
        margin: 0;
        font-size: 35px;
        padding: 0;
        background-color: #fff;
        color: $black-color;

        @include transition();

        @include hover() {
            color: $heading-color;

            #{$this}__title-more {
                color: $key-color-1;
            }

            i {
                color: $key-color-1;
            }
        }

        @include max-screen($md-max) {
            line-height: $default-line-height;
        }

        @include max-screen($sm-max) {
            font-size: 25px;
        }



        @include max-screen($xxs-max) {
            font-size: 16px;
        }

    }

    &__title-more {
        font-weight: $default-font-weight-bold;
        font-size: 15px;
        font-family: $default-font-family;
        color: $key-color-1;
        line-height: inherit;
        @include transition();
        margin-left: 30px;
        white-space: nowrap;

        @include max-screen($xxs-max) {
            font-size: 13px;
        }

    }

    &__content {
        overflow: auto;
        background-color: transparent;
        font-size: 15px;

        @include max-screen($xs-max) {
            font-size: 13px;
        }
    }

    &__content-inner {
        padding: 23px 33px 0 33px;

        @include max-screen($xxs-max) {
            padding: 10px 0 0 0;
        }


        ul.check-styled {
            li {
                &::before {
                    top: 2px;
                    content: '\e93f';
                    font-size: 16px;
                }
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;

        @include max-screen($xs-max) {
            flex-direction: column;
        }
    }

    &__price-item {
        color: #454a54;

        @include max-screen($xs-max) {
            align-self: flex-start;
        }
    }

    &__price-title {

    }

    &__price-amount {
        color: $key-color-1;

        @include max-screen($xs-max) {
            order: -1;
            align-self: flex-end;
        }
    }

    &__price-subtitle {
        font-size: 13px;
    }
}




/* custom-checkbox-with-accordions-container */

.custom-checkbox-with-accordions-container {
    //max-width: 545px;
}



/* custom-checkbox */

.custom-checkbox {
    width: 100%;
    padding: 25px 0;

    border-bottom: 2px solid $key-color-2;

    @include max-screen($xs-max) {
        padding: 20px 0;
    }

    &:last-child {
        border: none;
    }

    .checkbox__text.custom {
        padding-left: 40px;
        width: 100%;
        display: flex;
        align-items: center;

        @include max-screen($xs-max) {
            padding-left: 36px;
        }

        &::after {
            top: 0;
            left: 0;
            width: 28px;
            min-width: 28px;
            height: 28px;
            min-height: 28px;
            background-color: $white-color !important;

            @include max-screen($xs-max) {
                width: 24px;
                min-width: 24px;
                height: 24px;
                min-height: 24px;
            }
        }

        &::before {
            color: $key-color-5;
            //border-color: transparent $key-color-5 $key-color-5 transparent;
            top: 2px;
            left: 4px;
            width: 10px;
            height: 14px;
            border-top: 3px solid transparent;
            border-left: 3px solid transparent;
            border-right: 3px solid $key-color-5;
            border-bottom: 3px solid $key-color-5;

            @include max-screen($xs-max) {
                top: 2px;
                left: 4px;
                width: 8px;
                height: 12px;
            }
        }

        span.arrow {
            position: relative;
            margin-left: auto;
            margin-right: 0;
            font-size: 10px;
            @include transition();
            @include transform(rotate(0deg));
        }
    }

    &.is-active {
        .checkbox__text > span.arrow {
            @include transform(rotate(180deg));
        }
    }

    label.checkbox {
        width: 100%;
        //@extend %disabled;
    }

    .checkbox__label.custom {
        font-size: 18px;
        font-weight: $default-font-weight-bold;
        color: $default-font-color;
        white-space: normal;

        @include max-screen($xs-max) {
            font-size: 14px;
        }
    }

    .form__checkbox {
        margin-bottom: 20px;
    }
}
