.select2-dropdown {
    font-size: 14px;
    background-color: #FFF;

    border: 1px solid $border-color;
    box-shadow: 0 0 15px 0 rgba($key-color-1, 0.1);
    border-radius: 5px;

    box-sizing: border-box;

    display: block;

    position: absolute;
    left: -100000px;

    width: 100%;

    z-index: 400;
}

.select2-results {
    display: block;
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.select2-results__option {
    color: $key-color-1;
    padding: 13px 18px 12px;
    border-bottom: 1px solid lighten($key-color-1, 55%);
    @include transition();

    &:last-child {
        border-bottom: 0 none;
    }

    user-select: none;
    -webkit-user-select: none;

    &[aria-selected] {
        cursor: pointer;
    }
}

.select2-container--open .select2-dropdown {
    left: 0;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .select2-search--dropdown {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-search--dropdown {
    display: block;
    padding: 12px;
    background-color: lighten($border-color, 5%);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    position: relative;

    .select2-search__field {
        padding: 8px;
        width: 100%;
        box-sizing: border-box;

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }

    &.select2-search--hide {
        display: none;
    }
}
