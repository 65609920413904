.subheader {
  width: 100%;
  position: relative;
  z-index: 9;

  &.fixed-menu {
    top: 0;
  }

  .is-top-shadow & {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }

  .is-top-minimize & {
    position: fixed;

    &.fixed-menu--fixed-out {
      top: -300px;
    }

    &.fixed-menu--fixed-in {
      transform: translateY(0);
      visibility: visible !important;
      opacity: 1 !important;
      transition: all .5s ease-in-out;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

      @include max-screen($md-max) {
        padding: 15px 0;
        transform: translateY(-15px);
      }

      .is-menu-open & {
        opacity: 0 !important;
        transform: translateY(-125px);
        visibility: hidden !important;
      }
    }
  }


}


.panel {
  width: 100%;
  background-color: $white-color;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  html.main-page & {
    height: 85px;
    margin-bottom: 33px;
  }

  &__inner {
    width: 100%;
    padding: 0 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    html.main-page & {
      justify-content: center;
    }

    @include max-screen($sm-max) {
      padding: 0 21px;
    }
  }

  &__title {
    font-size: 25px;
    color: $default-font-color;
    font-weight: $default-font-weight-bold;
    white-space: nowrap;

    @include max-screen($sm-max) {
      font-size: 20px;
    }

    &--cms {
      font-size: 20px;
      letter-spacing: -1px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 27px;
    color: $black-color;
    font-weight: $default-font-weight-semibold;

    i {
      margin-right: 10px;
    }

    &-name {
      font-size: 14px;

      strong {
        color: $key-color-5;
      }
    }
  }
}


/* LABEL ICON */

.label-icon {
  position: relative;
  display: flex;

  i {

  }

  span.label {
    position: absolute;
    top: -7px;
    left: 5px;
    width: 14px;
    min-width: 14px;
    height: 14px;
    min-height: 14px;
    border-radius: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $key-color-5;
    color: $white-color;
    font-size: 10px;
  }
}
