
.gridzy {
  display: block;
  will-change: width, height
}

.gridzy > .gridzyItem {
  will-change: left, top, width, height, opacity, transform
}

.gridzy > :not(.gridzyItem) {
  visibility: hidden
}

.gridzyItemHidden {
  display: none
}

.gridzy .gridzyImage {
  vertical-align: middle;
  width: 100%;
  height: 100%
}

.gridzyAnimated {
  height: 0;
  transition: height .4s
}

.gridzyAnimated .gridzyItem {
  transition: all .4s
}

.gridzyAnimated .gridzyItemInitialToVisible {
  animation-name: gridzyItemInitialToVisibleAnimation;
  animation-fill-mode: forwards;
  animation-duration: .4s
}

@keyframes gridzyItemInitialToVisibleAnimation {
  0% {
    opacity: 0;
    transform: scale(.9)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
}

.gridzyAnimated .gridzyItemHiddenToVisible {
  animation-name: gridzyItemHiddenToVisibleAnimation;
  animation-fill-mode: forwards;
  animation-duration: .4s
}

@keyframes gridzyItemHiddenToVisibleAnimation {
  0% {
    opacity: 0;
    transform: scale(.9)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
}

.gridzyAnimated .gridzyItemVisibleToHidden {
  animation-name: gridzyItemVisibleToHiddenAnimation;
  animation-fill-mode: forwards;
  animation-duration: .4s
}

@keyframes gridzyItemVisibleToHiddenAnimation {
  0% {
    opacity: 1;
    transform: scale(1)
  }
  100% {
    opacity: 0;
    transform: scale(.9)
  }
}

.gridzyItem {
  opacity: 1
}

.gridzyItemLoading {
  opacity: .3;
  box-shadow: inset 0 0 15px -5px #666
}

.gridzyItemLoading::before {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  padding: 0;
  z-index: 1;
  opacity: 1;
  background: 0 0;
  border: 2px solid;
  border-color: transparent transparent #000 #000;
  border-radius: 50%;
  outline: 0;
  animation-duration: 1s;
  animation-name: gridzyProgressIndicator;
  animation-iteration-count: infinite;
  animation-timing-function: linear
}

@keyframes gridzyProgressIndicator {
  0% {
    transform: rotate(0);
    left: 50%
  }
  100% {
    transform: rotate(360deg);
    left: 50%
  }
}




/* skin classic */


.gridzySkinClassic > .gridzyItem, .gridzySkinClassicLight > .gridzyItem {
  overflow: hidden
}

.gridzySkinClassic .gridzyImage, .gridzySkinClassicLight .gridzyImage {
  position: relative;
  vertical-align: middle;
  width: 100%;
  height: 100%
}

.gridzySkinClassic .gridzyCaption, .gridzySkinClassicLight .gridzyCaption {
  margin: 0;
  padding: 1em;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  transition: transform .5s;
  transform: translateY(100%)
}

.gridzySkinClassic > .gridzyItem:hover .gridzyCaption, .gridzySkinClassicLight > .gridzyItem:hover .gridzyCaption {
  transform: translateY(0)
}

.gridzySkinClassic .gridzyCaption {
  background: rgba(0, 0, 0, .7);
  color: #fff
}

.gridzySkinClassicLight .gridzyCaption {
  background: rgba(255, 255, 255, .7);
  color: #000
}


/* skin alt */


.gridzySkinAlt > .gridzyItem, .gridzySkinClassicLight > .gridzyItem {
  overflow: hidden
}

.gridzySkinAlt .gradient {
  position: relative;

  &::before {
    @extend %disabled;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 80%);
    z-index: 1;
  }
}

.gridzySkinAlt .gridzyImage {
  position: relative;
  vertical-align: middle;
  width: 100%;
  height: 100%
}

.gridzySkinAlt .gridzyCaption {
  margin: 0;
  padding: 1em;
  position: absolute;
  z-index: 1;
  top: 25px;
  left: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  transition: transform .5s;
  transform: translateY(0)
}

.caption-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cation-title {
  font-size: 20px;
  font-weight: $default-font-weight-bold;
  color: $white-color;
}

.pic-count {
  //position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //top: 50px;
  //right: 50px;
  font-size: 20px;
  font-weight: $default-font-weight-bold;
  color: $white-color;
  z-index: 3;

  i {
    font-size: 26px;
  }

  span {
    margin-left: 13px;
  }
}

.gridzySkinAlt > .gridzyItem:hover .gridzyCaption {
  transform: translateY(-200%)
}

.gridzySkinAlt .gridzyCaption {
  background: rgba(0, 0, 0, 0);
  color: #fff
}
