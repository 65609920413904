*,
*:before,
*:after,
*:hover,
*:focus {
	box-sizing: border-box;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

html,
body {
	//overflow-x: hidden;
}

html {
	font-size: 16px;
}

body {
	font-family: $default-font-family;
	font-weight: $default-font-weight;
	color: $default-font-color;
	font-size: $default-font-size;
	line-height: $default-line-height;
	margin: $clear-margin;
	padding: $clear-padding;
	position: relative;
	-webkit-appearance: none;
	background: $bg-color-body;
	@include transition(padding-top);
	//padding-top: 125px;

	@include max-screen($md-max) {
		//padding-top: 87px;
	}
	//html.is-top-minimize & {
	//    padding-top: 75px;
	//}
}

h1,
.h1,
%h1 {
	font-size: $heading-1-size;
	font-weight: $heading-1-weight;
	margin: $heading-1-margin;
	color: $heading-1-color;
	line-height: $heading-1-line-height;
}

h2,
.h2,
%h2 {
	font-size: $heading-2-size;
	font-weight: $heading-2-weight;
	margin: $heading-2-margin;
	color: $heading-2-color;
	line-height: $heading-2-line-height;
}

h3,
.h3,
%h3 {
	font-size: $heading-3-size;
	font-weight: $heading-3-weight;
	margin: $heading-3-margin;
	color: $heading-3-color;
	line-height: $heading-3-line-height;
}

h4,
.h4,
%h4 {
	font-size: $heading-4-size;
	font-weight: $heading-4-weight;
	margin: $heading-4-margin;
	color: $heading-4-color;
	line-height: $heading-4-line-height;
}

h5,
.h5,
%h5 {
	font-size: $heading-5-size;
	font-weight: $heading-5-weight;
	margin: $heading-5-margin;
	color: $heading-5-color;
	line-height: $heading-5-line-height;
}

h6,
.h6,
%h6 {
	font-size: $heading-6-size;
	font-weight: $heading-6-weight;
	margin: $heading-6-margin;
	color: $heading-6-color;
	line-height: $heading-6-line-height;
}

p {
	margin: $default-margin;
}

strong,
b {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	font-weight: $default-font-weight-bold;
}

em,
i {
	color: inherit;
}

a,
svg {
	@include transition();
}

a {
	font-weight: $link-weight;
	color: $link-color;
	text-decoration: none;
	cursor: pointer;

	@include hover() {
		color: $link-color-hover;
	}
}

a[href^='tel'] {
	color: inherit;
	font-weight: inherit;
	text-decoration: inherit;
}

svg {
	// width: inherit;
	// height: inherit;
}

ol {
	margin: $default-margin;

	li {
		margin: $list-li-margin;
	}

	ol,
	ul,
	ul.list-styled {
		margin: $list-sub-margin;
	}
}

ul {
	padding: $clear-padding;
	margin: $clear-margin;
	list-style: none;
}

ul.list-styled,
.wysiwyg ul {
	margin: $default-margin;

	li {
		margin: $list-li-margin;
		padding: $list-li-padding;
		position: relative;

		&::before {
			content: $list-li-icon-content;
			font-family: $list-li-font-family;
			font-size: $list-li-icon-size;
			color: $list-li-icon-color;
			position: absolute;
			top: $list-li-icon-top;
			left: $list-li-icon-left;
		}
	}

	ol,
	ul,
	ul.list-styled {
		margin: $list-sub-margin;
	}

	ol li {
		padding-left: 0;
	}

	ol li:before {
		display: none;
		content: '';
	}
}

img {
	max-width: 100%;
	vertical-align: middle;
}

small {
	font-size: 85%;
}

abbr[title] {
	border-bottom: 1px dotted;
}

mark,
.mark {
	padding: 2px 5px;
	background-color: #fcf8e3;
}

abbr[title],
abbr[data-original-title] {
	cursor: help;
	border-bottom: 1px dotted #777;
}

address {
	margin-bottom: 20px;
	font-style: normal;
	line-height: 1.42857143;
}

code,
kbd,
pre,
samp {
	font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}

code {
	padding: 2px 4px;
	font-size: 90%;
	color: #c7254e;
	background-color: #f9f2f4;
	border-radius: 4px;
}

kbd {
	padding: 2px 4px;
	font-size: 90%;
	color: #fff;
	background-color: #333;
	border-radius: 3px;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: $default-font-weight-bold;
	box-shadow: none;
}

pre {
	font-family: inherit;
	font-size: inherit;
	white-space: pre-wrap;
	word-break: break-all;
	word-wrap: break-word;
}

pre code {
	padding: 0;
	font-size: inherit;
	color: inherit;
	white-space: pre-wrap;
	background-color: transparent;
	border-radius: 0;
}

sup,
sub {
	font-size: 80%;
	height: 0;
	line-height: 1;
	position: relative;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
}

sub {
	top: 0.5ex;
}

hr {
	background: #d0d0d0;
	border: 0 none;
	font-size: 0;
	height: 1px;
	line-height: 0;
	margin: 30px 0;
	clear: both;
}

::selection {
	background: $selection-bg-color;
	color: $selection-color;
}

::-moz-selection {
	background: $selection-bg-color;
	color: $selection-color;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	margin: $default-margin;
	font-family: inherit;
}

table th,
table td {
	padding: 15px 25px;
	line-height: $default-line-height;
	vertical-align: middle;
	border: 1px $border-color solid;
}

table th {
	font-weight: $default-font-weight-bold;
	text-align: left;
	background-color: lighten($border-color, 15%);
}

blockquote {
	color: #5c5c5c;
	background-color: #f6f6f6;
	font-size: $default-font-size;
	font-style: italic;
	font-weight: $default-font-weight;
	line-height: $default-line-height;
	margin: $default-margin;
	padding: 20px 25px;
	position: relative;
	border-left: 5px solid $key-color-1;
}

blockquote p {
	margin: $clear-margin;
}

blockquote cite {
	display: block;
	margin: 5px 0 0;
	color: #2d2d2d;
	font-size: 15px;
	font-style: normal;
	font-weight: $default-font-weight-bold;
}

span.yellow {
	color: $key-color-5;
}

.styles-icons-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-start;

	i {
		font-size: 30px;
		margin: 0 15px 10px 0;
	}

	.ico-tooltip {
		position: relative;
		font-size: 24px;

		.path2 {
			margin-left: -12px;
		}
	}
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

.w-100 {
	width: 100%;
}

#f-choose-category {
	position: relative;

	&::after {
		position: absolute;
		content: '';
		top: 0;
		display: block;
		width: 100px;
		height: 100px;
		background-color: red;
	}
}

.App {
	background-color: #f1f5f8;
}

.custom-tooltip {
	background-color: #364a59;
	font-size: 12px;
	color: #fff;
	min-width: 120px;
	border-radius: 4px;
	padding: 20px;
	position: absolute;
	top: 0;
	white-space: nowrap;
	transform: translate(-50%, -115%);
	left: 50%;
	z-index: 10;
	&::before {
		content: '';
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #364a59;
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translateX(-50%);
	}
}
.modal__close {
	top: -50px !important;
	right: -15px !important;
}

.custom-tooltip {
	background-color: #364a59;
	font-size: 12px;
	color: #fff;
	min-width: 120px;
	border-radius: 4px;
	padding: 20px;
	position: absolute;
	top: 0;
	white-space: nowrap;
	transform: translate(-50%, -115%);
	left: 50%;
	z-index: 10;
	&::before {
		content: '';
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #364a59;
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.success {
	color: #4caf50 !important;
}

.error {
	color: #f44336 !important;
	font-size: 12px;
}

.a-disabled {
	pointer-events: none;
	opacity: 0.8;
}
