.color-key-1 {
  color: $key-color-1 !important;
}

.color-key-2 {
  color: $key-color-2 !important;
}

.bg-color-key-1 {
  background-color: $key-color-1 !important;
}

.bg-color-key-2 {
  background-color: $key-color-2 !important;
}

.lead {
  font-size: $default-lead-font-size;
  font-weight: $default-lead-font-weight;
}

.section {
  padding: 28px 0;

  &--last {
    padding-top: 0;
    padding-bottom: 98px;
  }
}

.main-title {
  @extend %h1;
  font-size: 20px;
  color: $default-font-color;
  font-weight: $default-font-weight-bold;
  margin-bottom: 18px;

  &--25 {
    font-size: 25px;
    letter-spacing: -1px;
  }

  &--mb-45 {
    margin-bottom: 45px;
  }
}

.main-title-heading {
  font-size: 14px;
  font-weight: $default-font-weight-semibold;
  color: $black-color;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 43px;

  @include max-screen($sm-max) {
    margin-bottom: 20px;
  }
}

.medium-title {
  font-size: 22px;
  letter-spacing: -1.22px;
  color: $default-font-color;
  font-weight: $default-font-weight-bold;
  margin-bottom: 21px;

  &--mb-40 {
    margin-bottom: 40px;
  }
}

.main-text {
  font-size: 13px;
  color: $black-color;
}

.main-description {
  font-size: 17px;
  color: $key-color-4;
}

.main-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-screen($sm-max) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  > .main-title {
    margin-left: 30px;
    margin-bottom: 0;

    @include max-screen($sm-max) {
        margin-left: 0;
      margin-bottom: 30px;
    }
  }
}


.function-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $key-color-6;
  font-size: 14px;
  font-weight: $default-font-weight-semibold;
  @include transition();

  @include hover() {
    color: $key-color-2;
  }

  i {
    margin-right: 10px;
  }
}

.contact-help-wrapper {
  @include max-screen($sm-max) {
    margin-bottom: 50px;
  }
}


.contact-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 25px;
  color: $key-color-1;
  font-weight: $default-font-weight-semibold;
  margin-bottom: 22px;
  @include transition();

  @include max-screen($xs-max) {
    font-size: 20px;
  }

  @include max-screen($xxs-max) {
    font-size: 18px;
  }

  a {
    font-weight: inherit;
  }

  @include hover() {
    a {
      padding-left: 5px;
    }
  }

  span.icon {
    margin-right: 20px;
    font-size: inherit;
  }
}

.icon-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: $default-font-weight-bold;
  color: $default-font-color;
  letter-spacing: -1.39px;
  margin-bottom: 30px;

  i {
    color: #6BAD5F;
  }

  span {
    margin-left: 21px;
  }

}


.input-container {


  > input {
    position: relative;
    z-index: 1;
    height: 57px;
  }

  .floating-icon {
    top: 17px;
    right: 23px;
    font-size: 20px;
    color: #93B8D5;
    position: absolute;
    z-index: 3;

    .ico-tooltip span.path2::before {
      margin-left: -20px;
    }
  }
}

.select-tooltip-container {
  > select {
    position: relative;
    z-index: 1;
  }

  .floating-icon {
    top: 42px;
    right: 0;
    font-size: 20px;
    color: #93B8D5;
    position: absolute;
    z-index: 3;

    .ico-tooltip span.path2::before {
      margin-left: -30px;
    }
  }
}


#typepass {
  height: 20px;
}


.price {
  font-size: 24px;
  font-weight: $default-font-weight-bold;
  color: $key-color-1;
  line-height: 1;
  white-space: nowrap;

  &--40 {
    font-size: 40px;
  }
}

