.white-box {
  width: 100%;
  background-color: $white-color;
  margin-bottom: 10px;

  &--selectors {

    @include max-screen($md-max) {
        background-color: transparent;
    }
  }



  &__inner {
    padding: 24px 26px;
    width: 100%;

    @include max-screen($xs-max) {
        padding: 20px;
    }

    .white-box--selectors & {
      @include max-screen($md-max) {
          padding: 0;
      }
    }

    .white-box--library & {
      padding: 42px 51px;

      @include max-screen($lg-max) {
        padding: 42px 26px;
      }
    }

    .white-box--larger & {
      padding: 42px 51px;

      @include max-screen($lg-max) {
        padding: 42px 26px;
      }
    }
  }


  &__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 14px;

    .main-title {
      margin: 0;
    }

    .function-button {
      margin-left: 28px;
    }
  }
}


.blue-box {
  width: 100%;
  background-color: $key-color-8;
  border: 2px solid $key-color-7;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;

  &__inner {
    width: 100%;
    padding: 40px 133px 50px 170px;
    @include max-screen($sm-max) {
      padding: 30px;
    }
  }
}
