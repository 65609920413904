.invoices {
  font-size: 13px;
  color: $black-color;
  line-height: 1.60;


  &__head-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include max-screen($sm-max) {
        flex-direction: column;
      align-items: flex-start;
    }
  }

  &__head-left {

  }

  &__head-right {

    .search-input {
      width: 272px;
    }
  }

  &__row {
    padding: 15px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 2px solid #BCD3E5;

    &:last-child {
      border-bottom: none;
    }
  }

  &__text {
    font-size: 15px;
    color: $black-color;
  }

  &__download {
    margin-left: 80px;
    font-size: 15px;
    color: $black-color;
    white-space: nowrap;
    @include transition();

    @include max-screen($md-max) {
        margin-left: 0;
    }

    @include hover() {
      color: $key-color-5;
    }

    i {
      color: $key-color-5;
      margin-right: 6px;
      font-size: 16px;
    }
  }

  &__table-container {
    width: 100%;
    min-width: 500px;
    overflow-x: auto;
  }
}


table.invoices-table {
  background-color: $white-color;
  border: none;
  font-size: 15px;
  color: $black-color;


  tr, td {
    background-color: inherit;
    border: none;
    padding: 15px 0;
  }

  thead {

    th {
      border: none;
      border-bottom: 2px solid #BCD3E5;
      padding: 15px;
      padding-left: 0;
      background-color: inherit;
      white-space: nowrap;
    }

    @include max-screen($md-max) {
      display: none;
    }

  }

  tbody {

    @include max-screen($md-max) {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-flow: row wrap;
      overflow: hidden;
    }

    tr {
      background-color: inherit;
      border: none;

      @include max-screen($md-max) {
        height: inherit;
        display: block;
        border: 1px solid #e7e5e1;
        margin: 5px;
        width: calc(50% - 10px);
        padding: 30px;

        td {
          display: flex;
          flex-direction: column;
          border: 0 none;
          padding: 0;
          text-align: center;
          max-width: 100%;
          white-space: normal;

          + td {
            margin-top: 15px;
          }
        }
      }

      @include max-screen($xs-max) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    td {
      border-bottom: 2px solid #BCD3E5;
      padding: 15px 0;
      background-color: inherit;

      @include max-screen($xs-max) {
          padding: 12px 15px;
      }
    }

    td.td-action {
      text-align: right;
      padding-right: 10px;

      @include max-screen($md-max) {
          padding-right: 0;
        text-align: center;
      }
    }

    tr:last-child td {
      border: none;
    }

    .invoices-table__rwd-label {
      display: none;
      color: $key-color-5;
      font-weight: $default-font-weight;
      text-transform: uppercase;
      margin-bottom: 3px;
      font-size: 10px;

      @include max-screen($md-max) {
        display: block;
        text-align: center;
        width: 100%;
      }
    }


  }


}
