/*
 * 	Default theme - Owl Carousel CSS File
 */

 $owl-color-base:			$black-color !default;
 $owl-color-white:			#FFF !default;
 $owl-color-gray:			#D6D6D6 !default;
 
 //nav
 
 $owl-nav-color: 			$owl-color-base !default;
 $owl-nav-color-hover:		$owl-color-white !default;
 $owl-nav-font-size: 		24px !default;
 $owl-nav-rounded: 			0 !default;
 $owl-nav-margin: 			0 !default;
 $owl-nav-padding: 			0 !default;
 $owl-nav-background: 		rgba(255,255,255, 0.9) !default;
 $owl-nav-background-hover:	rgba(0,0,0, 0.9) !default;
 $owl-nav-disabled-opacity: 0.5 !default;
 
 //dots
 
 $owl-dot-width:			10px !default;
 $owl-dot-height:			10px !default;
 $owl-dot-rounded:			30px !default;
 $owl-dot-margin: 			5px 7px !default;
 $owl-dot-background:		$owl-color-gray !default;
 $owl-dot-background-active:$owl-color-base !default;
 

 .owl-carousel {
	// Styling Next and Prev buttons
	.owl-nav {
		z-index: 100;
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		@include translate(0,-50%);
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		pointer-events: none;
		display: flex;
		justify-content: space-between;


		button.owl-prev,
        button.owl-next {
			color: $owl-nav-color;
			font-size: $owl-nav-font-size;
			margin: $owl-nav-margin;
			padding: $owl-nav-padding;
			background: $owl-nav-background;
			width: 55px;
			min-width: 55px;
			height: 55px;
			min-height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
            border-radius: 0;
            pointer-events: auto;
            @include transition;


			@include max-screen($xs-max) {
				background-color: transparent !important;
				color: $white-color !important;
			}

			&:hover {
				background: $owl-nav-background-hover;
				color:$owl-nav-color-hover;
				text-decoration: none;
			}
		}
		.disabled {
			opacity: $owl-nav-disabled-opacity;
			cursor: default;
		}
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 10px;
	}

	.owl-dots {
        z-index: 100;
		position: absolute;
		width: 100%;
		bottom: 50px;
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		pointer-events: none;
		display: flex;
        justify-content: center;

        @include max-screen($sm-max) {
			bottom: 30px;
        }
        
		button.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;
            pointer-events: auto;

			span {
				width: $owl-dot-width;
				height: $owl-dot-height;
				margin: $owl-dot-margin;
				background: $owl-dot-background;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
                border-radius: $owl-dot-rounded;
                @include transition;
			}

			&.active,
			&:hover {
				span {
					background: $owl-dot-background-active;
				}
			}
		}
	}
}


.slider-large-container {
	text-align: center;

	.slider-title {
		font-size: 25px;
		font-weight: $default-font-weight-bold;
	}

	&--first {
		@include max-screen($md-max) {
		    margin-bottom: 30px;
		}
	}
}

.slider-container {
	position: relative;
	z-index: 3;

	.slider-counter {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		top: 50px;
		right: 50px;
		font-size: 20px;
		font-weight: $default-font-weight-bold;
		color: $white-color;
		z-index: 3;

		@include max-screen($xs-max) {
		    top: 30px;
			right: 30px;
		}

		i {
			font-size: 26px;
		}

		span {
			margin-left: 13px;
		}
	}


	.slider-switcher {
		position: absolute;
		bottom: 47px;
		left: 50%;
		@include translate(-50%,0);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 3;
		font-size: 14px;
		color: $white-color;
		font-weight: $default-font-weight-semibold;
		line-height: 1;

		@include max-screen($xs-max) {
		    bottom: 30px;
		}

		a {
			color: $white-color;
			font-weight: inherit;
			margin: 0 8px;
			padding: 9px 10px;
			background-color: transparent;

			&.active {
				background-color: $white-color;
				color: $black-color;
			}
		}

		&__label {
			margin-right: 8px;

			@include max-screen($xxs-max) {
			    display: none;
			}
		}

		&__select {
			@include max-screen($xxs-max) {
				display: none;
			}
		}
	}

}



.slider {

	&__item {
		position: relative;
		z-index: 2;

		width: 100%;
		height: 475px;

		@include max-screen($lg-max) {
		    height: 405px;
		}

		@include max-screen($md-max) {
			height: 640px;
		}

		@include max-screen($sm-max) {
			height: 480px;
		}

		@include max-screen($xs-max) {
		    height: 360px;
		}

		@include max-screen($xxs-max) {
		    height: 280px;
		}

		&::before {
			@extend %disabled;
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgb(0,0,0);
			background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 60%);
			z-index: 2;
		}

		a {
			width: 100%;
			height: inherit;
			img {
				width: 100%;
				height: inherit;
				object-fit: cover;
			}
		}
	}
}

.slider-select {
  background-color: white;
  /* inline SVG */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius:2px;
  border:none;
  color: $black-color;
  padding: 9px 30px 9px 10px;
  // disable default appearance
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 73px;
  &::-ms-expand { display: none };
}



.categories-slider {
	position: relative;
	width: 100%;

	.owl-nav {
		z-index: 100;
		position: absolute;
		width: 100%;
		right: 0;
		top: 50%;
		//@include translate(0,-50%);
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		pointer-events: none;
		display: flex;
		justify-content: flex-end;


		button.owl-prev,
		button.owl-next {
			color: $key-color-5;
			font-size: $owl-nav-font-size;
			margin: 0;
			padding: 0;
			width: 40px;
			height: 20px;
			min-width: 40px;
			min-height: 20px;
			background: none;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			border-radius: 0;
			pointer-events: auto;
			@include transition;

			&:hover {
				background: none;
				color:$black-color;
				text-decoration: none;
			}
		}
		.disabled {
			opacity: $owl-nav-disabled-opacity;
			cursor: default;
		}
	}


	&__item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-right: 80px;

		@include max-screen($md-max) {
		    flex-wrap: wrap;
		}
	}

	&__small-category {
		border: none;
		background: none;
		box-shadow: none;
		padding: 0;
		cursor: pointer;
		white-space: nowrap;
		position: relative;
		font-size: 15px;
		color: #252525;
		margin-right: 13px;
		font-weight: $default-font-weight;
		@include transition();

		@include max-screen($md-max) {
		    margin-bottom: 5px;
		}

		&::before {
			position: absolute;
			content: '';
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #252525;
			opacity: 0;
			@include transition();
		}

		@include hover() {
			&::before {
				opacity: 1;
			}
		}

		&.active {
			font-weight: $default-font-weight-semibold;
			&::before {
				opacity: 1;
			}
		}
	}

}
