/*

BASKET

*/

.basket {
  width: 100%;
  border-bottom: 2px solid $key-color-2;
  padding: 31px 0;

  &.last {
    border: none;
    margin-bottom: 20px;
  }


  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include max-screen($sm-max) {
      flex-direction: column;

      .price {
        display: flex;
        order: 2;
        margin-bottom: 20px;
      }
    }
  }

  &__photo {
    margin-right: 23px;

    @include max-screen($sm-max) {
      width: 100%;
      margin: 0 0 25px 0;
      order: 1;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__info {
    max-width: 300px;

    @include max-screen($sm-max) {
      margin-right: 0;
      max-width: initial;
      margin-bottom: 0;
      order: 3;
    }

    &-item {
      margin-bottom: 10px;
      font-weight: $default-font-weight-semibold;
    }

    .more {
      padding-left: 5px;
      font-size: 13px;
      font-weight: $default-font-weight-bold;
    }
  }

}


.basket-footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;


  .btn {
    @extend %disabled;
    color: $key-color-1;
    font-weight: $default-font-weight-bold;

    span {
      margin-left: 3px;
    }

    @include max-screen($xs-max) {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  @include max-screen($xs-max) {
    flex-direction: column;
  }
}


.basket-middle {
  width: 100%;
  margin-bottom: 40px;

  &__item {
    font-size: 17px;
    font-weight: $default-font-weight-bold;
    color: $black-color;
    margin-bottom: 5px;

    @include max-screen($xs-max) {

      span {
        display: block;
      }

    }

    span {
      font-weight: $default-font-weight;
    }
  }
}

.basket-sumup {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  @include max-screen($xs-max) {
    margin-left: 0;
    margin-right: auto;
    text-align: left;
    align-items: flex-start;
  }

  &__title {
    font-weight: $default-font-weight-bold;
    font-size: 14px;
    color: $key-color-1;
    margin-bottom: 5px;
  }


}


.basket-title-with-tooltip {
  position: relative;
  font-size: 25px;
  font-weight: $default-font-weight-bold;
  color: $default-font-color;
  letter-spacing: -1.39px;
  margin-bottom: 40px;
  text-align: center;
  padding: 0 35px;

  @include max-screen($xxs-max) {
    font-size: 20px;
    padding: 0 20px 0 0;
    text-align: left;
  }

  .tooltiip {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.basket-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include max-screen($xs-max) {
    justify-content: center;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
