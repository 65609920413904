//
// .form
//
$form-margin: $default-margin;


//
// .form__group
//
$form-group-margin: 0 0 13px;


//
// .form__info
//
$form-info-opacity: 1;
$form-info-margin: 15px 0 0;
$form-info-font-size: 12px;


//
// .form__label
//
$form-label-color: $key-color-1;
$form-label-font-size: 13px;
$form-label-font-weight: $default-font-weight-bold;
$form-label-margin: 0 0 10px;

$form-label-horizontal-text-align: left;
$form-label-horizontal-margin: 16px 0 0;
$form-label-horizontal-margin-mobile: 0 0 8px;
$form-label-horizontal-width: 180px;
$form-label-horizontal-width-mobile: 100%;


//
// .form__field
//
$form-field-horizontal-margin: 0 0 0 200px;
$form-field-horizontal-margin-mobile: 0;


//
// .form__plaintext
//
$form-plaintext-border: 1px solid transparent;
$form-plaintext-color: $default-font-color;
$form-plaintext-font-size: 14px;
$form-plaintext-font-weight: $default-font-weight;
$form-plaintext-padding: 15px 0;


//
// .form__actions
//
$form-actions-margin: 15px 0 0;
$form-actions-horizontal-margin: 0 0 0 200px;
$form-actions-horizontal-margin-mobile: 0;


//
// placeholder
//
$form-placeholder-color: #A1A1A1;


//
// input, select, textarea
//
$form-fields-font-family: $default-font-family;
$form-fields-font-size: 14px;
$form-fields-font-weight: 600;

$form-fields-bg: #fff;
$form-fields-border: 2px solid $key-color-2;
$form-fields-border-radius: 0;
$form-fields-color: $default-font-color;
$form-fields-padding: 18px 18px 17px;
$form-fields-native-select-padding: 18px 18px 17px;
$form-fields-width: 100%;

$form-fields-error-border: $color-danger;
$form-fields-focus-border: $key-color-2;
$form-fields-focus-shadow: 0 0 15px 0 rgba($key-color-1, 0.1);
$form-fields-disabled-border: #d9d9d9;
$form-fields-disabled-bg: #f2f2f2;
$form-fields-disabled-color: #b4b4b4;


//
// .form__checkbox, .form__radio
//
$form-chr-margin: 10px 0;


//
// .checkbox
//
$form-checkbox-display-type: inline-block;
$form-checkbox-line-height: $small-line-height;
$form-checkbox-cursor: pointer;
$form-checkbox-disabled-cursor: not-allowed;

$form-checkbox-text-padding: 2px 0 0 40px;
$form-checkbox-text-display-type: inline-block;

$form-checkbox-label-font-size: $form-label-font-size;

$form-checkbox-sign-top: 3px;
$form-checkbox-sign-left: 4px;
$form-checkbox-sign-width: 9px;
$form-checkbox-sign-height: 13px;
$form-checkbox-sign-radius: 1px;
$form-checkbox-sign-margin: 3px 0 0;
$form-checkbox-sign-border-top: 2px solid transparent;
$form-checkbox-sign-border-right: 2px solid $key-color-5;
$form-checkbox-sign-border-left: 2px solid transparent;
$form-checkbox-sign-border-bottom: 2px solid $key-color-5;
$form-checkbox-sign-transform: rotate(37deg);
$form-checkbox-sign-checked-disabled-border-right: 2px solid #a8a8a8;
$form-checkbox-sign-checked-disabled-border-bottom: 2px solid #a8a8a8;

$form-checkbox-shape-top: 0;
$form-checkbox-shape-left: 0;
$form-checkbox-shape-width: 27px;
$form-checkbox-shape-height: 27px;
$form-checkbox-shape-radius: 0;
$form-checkbox-shape-bg: $white-color;
$form-checkbox-shape-border: 2px solid $key-color-2;
$form-checkbox-shape-checked-bg: $white-color;
$form-checkbox-shape-checked-border: 2px solid $key-color-2;
$form-checkbox-shape-disabled-bg: #d9d9d9;
$form-checkbox-shape-disabled-border: 2px solid #d9d9d9;
$form-checkbox-shape-checked-disabled-bg: #d9d9d9;
$form-checkbox-shape-checked-disabled-border: 2px solid #d9d9d9;
$form-checkbox-shape-highlighted-box-shadow: 0 0 0 10px rgba($key-color-2, 0.1);
$form-checkbox-shape-highlighted-bg: rgba($key-color-2, 0.1);
$form-checkbox-shape-highlighted-checked-bg: $key-color-2;
$form-checkbox-shape-hovered-border: 2px solid $key-color-1;

$form-checkbox-info-margin: 2px 0 0;
$form-checkbox-info-opacity: $form-info-opacity;
$form-checkbox-info-font-size: $form-info-font-size;


//
// .radio
//
$form-radio-display-type: inline-block;
$form-radio-line-height: $small-line-height;
;
$form-radio-cursor: pointer;
$form-radio-disabled-cursor: not-allowed;

$form-radio-text-padding: 2px 0 0 35px;
$form-radio-text-display-type: inline-block;

$form-radio-label-font-size: $form-label-font-size;

$form-radio-sign-top: 12px;
$form-radio-sign-left: 10px;
$form-radio-sign-width: 10px;
$form-radio-sign-height: 10px;
$form-radio-sign-radius: 50%;
$form-radio-sign-bg: $key-color-5;
$form-radio-sign-transform: translate(-50%, -50%);
$form-radio-sign-checked-disabled-bg: #a8a8a8;

$form-radio-shape-top: 2px;
$form-radio-shape-left: 0;
$form-radio-shape-width: 20px;
$form-radio-shape-height: 20px;
$form-radio-shape-radius: 50%;
$form-radio-shape-bg: transparent;
$form-radio-shape-border: 2px solid $key-color-2;
$form-radio-shape-checked-bg: transparent;
$form-radio-shape-checked-border: 2px solid $key-color-2;
$form-radio-shape-disabled-bg: #d9d9d9;
$form-radio-shape-disabled-border: 2px solid #d9d9d9;
$form-radio-shape-checked-disabled-bg: #d9d9d9;
$form-radio-shape-checked-disabled-border: 2px solid #d9d9d9;
$form-radio-shape-highlighted-box-shadow: 0 0 0 10px rgba($key-color-2, 0.1);
$form-radio-shape-highlighted-bg: rgba($key-color-2, 0.1);
$form-radio-shape-highlighted-checked-bg: rgba($key-color-2, 0.1);
$form-radio-shape-hovered-border: 2px solid $key-color-1;

$form-radio-info-margin: 2px 0 0;
$form-radio-info-opacity: $form-info-opacity;
$form-radio-info-font-size: $form-info-font-size;

//---[end vars]-----------------------------------------//


::-webkit-input-placeholder {
  color: $form-placeholder-color;
}

:-moz-placeholder {
  color: $form-placeholder-color;
  opacity: 1;
}

::-moz-placeholder {
  color: $form-placeholder-color;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $form-placeholder-color;
}

input,
select,
textarea {
  border: $form-fields-border;
  border-radius: $form-fields-border-radius;
  color: $form-fields-color;
  font-family: $form-fields-font-family;
  font-size: $form-fields-font-size;
  font-weight: $form-fields-font-weight;
  width: 100%;
  margin: 0;
  padding: $form-fields-padding;
  line-height: inherit;
  background-color: $form-fields-bg;
  outline: none;
  appearance: none;
  @include transition;

  &.error {
    border-color: $form-fields-error-border !important;
  }

  &:focus {
    border-color: $form-fields-focus-border;
    box-shadow: $form-fields-focus-shadow;
  }

  &[disabled] {
    border-color: $form-fields-disabled-border;
    background-color: $form-fields-disabled-bg;
    color: $form-fields-disabled-color;
  }
}

input[type="checkbox"],
input[type="radio"] {
  width: auto;
}

textarea {
  margin: 0;
  resize: none;
  vertical-align: top;
}

select {
  padding: $form-fields-native-select-padding;
}

label.error {
  margin: 0;
  font-size: 11px;
  font-weight: $default-font-weight;
  background-color: $color-danger;
  color: #fff;
  border-radius: $default-radius;
  padding: 5px 9px;
  line-height: 1.2;
  position: relative;
  display: inline-block;
  margin-top: 6px;
  text-align: left;

  &::before {
    @include pseudo;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent $color-danger transparent;
    left: 6px;
    top: -4px;
  }
}

.form {
  $this: &;
  margin: $form-margin;

  &__group {
    margin: $form-group-margin;
    position: relative;

    .form__field {
      width: 100%;
      text-align: center;

      .error {
        font-size: 12px;
        color: red;
      }
    }

  }

  &__info {
    opacity: $form-info-opacity;
    color: #A1A1A1;
    display: block;
    margin: $form-info-margin;
    font-size: $form-info-font-size;
  }

  &__field {

    > .form__info {
      color: $black-color;
    }

    &--wrapper {
      @extend .flex, .flex--nowrap;

      @include max-screen($sm-max) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      .form__radio + .form__radio {
        margin-left: 30px;

        @include max-screen($sm-max) {
          margin-left: 0;
        }
      }
    }

    .form__short-selects & {
      .select {
        margin-right: 30px;
      }
    }
  }

  &__label {
    color: $form-label-color;
    display: block;
    font-size: $form-label-font-size;
    font-weight: $form-label-font-weight;
    margin: $form-label-margin;
    text-transform: uppercase;
  }

  &__plaintext {
    border: $form-plaintext-border;
    color: $form-plaintext-color;
    font-size: $form-plaintext-font-size;
    font-weight: $form-plaintext-font-weight;
    padding: $form-plaintext-padding;
    width: 100%;
  }

  &__input {

  }

  &__textarea {

  }

  &__checkbox,
  &__radio {
    margin: $form-chr-margin;
  }

  &__actions {
    margin: $form-actions-margin;
    .error {
      width: 100%;
      text-align: center;
      color: red;
    }

    @include max-screen($xs-max) {
      .btn {
        width: 100%;
        clear: both;
        display: block;
      }
    }

    &--short {
      margin-top: 15px;
      margin-right: 30px;


      @include max-screen($sm-max) {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  &__action-link {
    font-size: 85%;
  }

  &--horizontal {

    #{$this}__group {
      &--avatar-selector {
        //margin-top: 35px;

        #{$this}__label {
          margin-top: 25px;
        }
      }

      &--rating-selector {
        .rating {
          margin-top: -3px;
        }

        #{$this}__label {
          margin-top: 2px;
        }

        @include max-screen($xs-max) {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
    }


    #{$this}__label {
      text-align: $form-label-horizontal-text-align;
      margin: $form-label-horizontal-margin;
      float: left;
      width: $form-label-horizontal-width;

      @include max-screen($xs-max) {
        margin: $form-label-horizontal-margin-mobile;
        width: $form-label-horizontal-width-mobile;
        float: none;
      }
    }

    #{$this}__field {
      margin: $form-field-horizontal-margin;

      @include max-screen($xs-max) {
        margin: $form-field-horizontal-margin-mobile;
      }
    }

    #{$this}__actions {
      margin: $form-actions-horizontal-margin;

      @include max-screen($xs-max) {
        margin: $form-actions-horizontal-margin-mobile;
      }
    }
  }

  &.is-loading {
    position: relative;

    &::before {
      @include pseudo;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.7);
      background-image: url("../../img/preloaders/preloader-1.svg");
      background-position: center center;
      background-size: 100px 100px;
      background-repeat: no-repeat;
      z-index: 2;

      @include max-screen($xs-max) {
        background-size: 60px 60px;
        position: fixed;
      }

    }

    .modal & {
      &::before {
        background-size: 60px 60px;

        @include max-screen($xs-max) {
          background-size: 60px 60px;
        }
      }
    }
  }


  &--limited {
    max-width: 366px;

    @include max-screen($xs-max) {
      max-width: 100%;
    }
  }

  &--limited-with-buttons {
    .form__group {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include max-screen($xs-max) {

        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    .form__field {
      width: 366px;
      margin-right: 21px;

      @include max-screen($xs-max) {
        width: 100%;
      }
    }

    .form__actions {
      width: 366px;
      @include max-screen($xs-max) {
        width: 100%;
      }
    }

    .form-add-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      color: $key-color-6;
      font-weight: $default-font-weight-bold;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      @include transition();
      @include hover() {
        margin-left: 3px;
        color: $key-color-5;
      }

      @include max-screen($xs-max) {
        font-size: 13px;
        margin-top: 15px;
      }
    }
  }
}

.checkbox {
  $this: &;

  display: $form-checkbox-display-type;
  line-height: $form-checkbox-line-height;

  @include hover() {
    #{$this}__text {
      &::after {
        border: $form-checkbox-shape-hovered-border;
      }
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    @extend %disabled;

    &:not(:checked) {
      &:disabled {
        + #{$this}__text {
          &::after {
            border: $form-checkbox-shape-disabled-border;
            background-color: $form-checkbox-shape-disabled-bg;
          }
        }
      }
    }

    &:checked {
      + #{$this}__text {
        &::before {
          opacity: 1;
        }

        &::after {
          border: $form-checkbox-shape-checked-border;
          background-color: $form-checkbox-shape-checked-bg;
        }
      }

      &:disabled {
        + #{$this}__text {
          &::before {
            border-right: $form-checkbox-sign-checked-disabled-border-right;
            border-bottom: $form-checkbox-sign-checked-disabled-border-bottom;
          }

          &::after {
            border: $form-checkbox-shape-checked-disabled-border;
            background-color: $form-checkbox-shape-checked-disabled-bg;
          }
        }
      }
    }

    &:disabled {
      + #{$this}__text {
        cursor: $form-checkbox-disabled-cursor;
      }
    }

    &.is-tabbed {
      &:focus {
        + #{$this}__text {
          &::after {
            box-shadow: $form-checkbox-shape-highlighted-box-shadow;
            background-color: $form-checkbox-shape-highlighted-bg;
          }
        }

        &:checked {
          + #{$this}__text {
            &::after {
              background-color: $form-checkbox-shape-highlighted-checked-bg;
            }
          }
        }
      }
    }
  }

  &__text {
    position: relative;
    padding: $form-checkbox-text-padding;
    display: $form-checkbox-text-display-type;
    user-select: none;
    cursor: $form-checkbox-cursor;


    &--small {
      line-height: 1.1;
    }

    &::before {
      content: '';
      opacity: 0;
      position: absolute;
      top: $form-checkbox-sign-top;
      left: $form-checkbox-sign-left;
      width: $form-checkbox-sign-width;
      height: $form-checkbox-sign-height;
      border-radius: $form-checkbox-sign-radius;
      margin: $form-checkbox-sign-margin;
      z-index: 1;
      border-top: $form-checkbox-sign-border-top;
      border-left: $form-checkbox-sign-border-left;
      border-right: $form-checkbox-sign-border-right;
      border-bottom: $form-checkbox-sign-border-bottom;
      backface-visibility: hidden;
      transform-origin: 100% 100%;
      transform: $form-checkbox-sign-transform;
      @include transition();
    }

    &::after {
      content: '';
      position: absolute;
      top: $form-checkbox-shape-top;
      left: $form-checkbox-shape-left;
      width: $form-checkbox-shape-width;
      height: $form-checkbox-shape-height;
      border-radius: $form-checkbox-shape-radius;
      background-color: $form-checkbox-shape-bg;
      border: $form-checkbox-shape-border;
      z-index: 0;
      @include transition();
    }


  }

  &__label {
    font-size: $form-checkbox-label-font-size;

    .checkbox__text--small & {
      position: relative;
      top: -4px;
      font-size: 11px;
      color: $black-color;
    }

    a {
      font-weight: $default-font-weight-bold;
    }
  }

  &__info {
    display: block;
    margin: $form-checkbox-info-margin;
    opacity: $form-checkbox-info-opacity;
    font-size: $form-checkbox-info-font-size;
  }


}

.radio {
  $this: &;

  display: $form-radio-display-type;
  line-height: $form-radio-line-height;

  @include hover() {
    #{$this}__text {
      &::after {
        border: $form-radio-shape-hovered-border;
      }
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    @extend %disabled;

    &:not(:checked) {
      &:disabled {
        + #{$this}__text {
          &::after {
            border: $form-radio-shape-disabled-border;
            background-color: $form-radio-shape-disabled-bg;
          }
        }
      }
    }

    &:checked {
      + #{$this}__text {
        &::before {
          opacity: 1;
        }

        &::after {
          border: $form-radio-shape-checked-border;
          background-color: $form-radio-shape-checked-bg;
        }
      }

      &:disabled {
        + #{$this}__text {
          &::before {
            background-color: $form-radio-sign-checked-disabled-bg;
          }

          &::after {
            border: $form-radio-shape-checked-disabled-border;
            background-color: $form-radio-shape-checked-disabled-bg;
          }
        }
      }
    }

    &:disabled {
      + #{$this}__text {
        cursor: $form-radio-disabled-cursor;
      }
    }

    &.is-tabbed {
      &:focus {
        + #{$this}__text {
          &::after {
            box-shadow: $form-radio-shape-highlighted-box-shadow;
            background-color: $form-radio-shape-highlighted-bg;
          }
        }

        &:checked {
          + #{$this}__text {
            &::after {
              background-color: $form-radio-shape-highlighted-checked-bg;
            }
          }
        }
      }
    }
  }

  &__text {
    position: relative;
    padding: $form-radio-text-padding;
    display: $form-radio-text-display-type;
    user-select: none;
    cursor: $form-radio-cursor;

    &::before {
      content: '';
      opacity: 0;
      position: absolute;
      top: $form-radio-sign-top;
      left: $form-radio-sign-left;
      width: $form-radio-sign-width;
      height: $form-radio-sign-height;
      border-radius: $form-radio-sign-radius;
      background-color: $form-radio-sign-bg;
      transform-origin: 100% 100%;
      transform: $form-radio-sign-transform;
      z-index: 1;
      backface-visibility: hidden;
      @include transition();
    }

    &::after {
      content: '';
      position: absolute;
      top: $form-radio-shape-top;
      left: $form-radio-shape-left;
      width: $form-radio-shape-width;
      height: $form-radio-shape-height;
      border-radius: $form-radio-shape-radius;
      background-color: $form-radio-shape-bg;
      border: $form-radio-shape-border;
      z-index: 0;
      @include transition();
    }
  }

  &__label {
    font-size: $form-radio-label-font-size;

    a {
      font-weight: $default-font-weight-bold;
    }
  }

  &__info {
    display: block;
    margin: $form-radio-info-margin;
    opacity: $form-radio-info-opacity;
    font-size: $form-radio-info-font-size;
  }

  &--border {

    margin-bottom: 5px;
    cursor: pointer;
    @include transition();
    width: 100%;

    #{$this}__input:checked + #{$this}__text {
      border-color: $key-color-2;
      background-color: lighten($key-color-2, 72%);

      #{$this}__label {
        > strong {
          color: $key-color-1;
        }
      }
    }

    #{$this}__text {
      width: 100%;
      padding: 16px 15px 15px 50px;
      border: 1px solid #cacad4;
      border-radius: 5px;

      &::after {
        top: 13px;
        left: 15px;
      }

      &::before {
        top: 24px;
        left: 26px;
      }

      @include hover() {
        border-color: $key-color-2;
        background-color: lighten($key-color-2, 72%);

        &:after {
          background-color: #fff;
          border-color: $key-color-2;
        }

        #{$this}__label {

          > strong {
            color: $key-color-1;
          }
        }
      }
    }

    #{$this}__label {
      width: 100%;
      @extend .flex, .flex--nowrap, .flex--space-between;

      > span {
        margin-right: 30px;

        em {
          font-style: normal;
        }
      }

      > strong {
        white-space: nowrap;
        font-size: 14px;
        color: $default-font-color;
        @include transition();
      }

      @include max-screen($xxs-max) {
        display: inline;

        > span {
          margin-right: 0;
        }
      }
    }

    #{$this}__input:checked {

      #{this}__text {
        border-color: $key-color-2;
        background-color: lighten($key-color-2, 72%);
      }
    }
  }


}


.g-recaptcha {
  display: inline-block;
}


.input-wrapper {
  position: relative;

  .search-input {
    z-index: 0;
    height: 57px;
  }

  button {
    position: absolute;
    height: 57px;
    width: 57px;
    top: 0;
    right: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }

}


.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include max-screen($xs-max) {
    flex-direction: column;
    align-items: flex-start;
  }

  .form__field {
    width: 100%;
  }

  .form__field:first-child {
    max-width: 38%;
    margin-right: 13px;

    @include max-screen($xs-max) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .form__field {
    @include max-screen($xs-max) {
      width: 100%;
    }
  }
}

.return-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: $default-font-color;
  font-weight: $default-font-weight-bold;


  span {
    @include transition();
  }

  @include hover() {
    span {
      margin-left: 5px;
    }
  }

  i {
    margin-right: 8px;
    font-size: 10px;
  }
}


.radio-selector-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;

  .form__radio {
    margin-right: 30px;
  }

  .form__radio:nth-child(2) {
    margin-right: 15px;
  }
}

.form-title {
  font-size: 13px;
  color: $black-color;
  line-height: 1;
  font-weight: $default-font-weight-semibold;
  margin-bottom: 20px;
}


.text-small-info {
  font-size: 11px;
  color: $black-color;
  line-height: 1.36;
  margin-bottom: 10px;
}


.account-info-check {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include max-screen($sm-max) {
    &:first-child {
      margin-bottom: 10px;
    }
  }

  span {
    font-weight: $default-font-weight;
  }

  button {
    font-weight: $default-font-weight-bold;
    color: #364A59;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: none;

    &:hover {
      color: #537289;
    }
  }

}


.select-with-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 25px;

  > .form__field {
    width: 100%;
  }

  .btn {
    margin-left: 6px;
    height: 57px;
  }
}


.form-group-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include max-screen(375px) {
    flex-direction: column;

    > .form__group {
      width: 100%;
    }
  }

  > .form__group:first-child {
    margin-right: 13px;
    max-width: 120px;

    @include max-screen(375px) {
      max-width: initial;
      margin-right: 0;
    }
  }
}
