.documents {
  font-size: 13px;
  color: $black-color;
  line-height: 1.60;


  &__item {
    padding: 15px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 2px solid #BCD3E5;

    &:last-child {
      border-bottom: none;
    }
  }

  &__text {
    font-size: 15px;
    color: $black-color;
  }

  &__download {
    margin-left: 80px;
    font-size: 15px;
    white-space: nowrap;
    color: $black-color;
    @include transition();

    @include max-screen($xs-max) {
        margin-left: 20px;
    }

    @include hover() {
      color: $key-color-5;
    }

    i {
      color: $key-color-5;
      margin-right: 6px;
      font-size: 16px;
    }
  }
}
