.dropdown {
    $this: &;
    
	position: relative;
    line-height: 1;

	&__trigger {
        cursor: pointer;
        
		@include hover() {
			color: inherit;
		}
        
        .is-dropdown-open & {
            color: inherit;
        }
	}

    &__trigger-icon {
        padding-right: 20px;
        position: relative;
        color: inherit;
        
        &::before {
           /* 
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid $default-font-color;
            width: 0;
            height: 0;
            content: '';
            */
            font-family: $project-font-family-icon;
            content: "\e022";
            font-size: 9px;
            position: absolute;
            right: 3px;
            top: 50%;
            transform: translateY(-50%);
            @include transition();
            transform-origin: center;
        }

        .is-dropdown-open & {
            &::before {
                transform: translateY(-50%) rotate(180deg)
                //margin-top: -2px;
                //border-top-color: inherit;
            }
        }
    }
    
    &__inner {
        background-color: #fff;
        border-radius: $default-radius;
        overflow: hidden;
        box-shadow: $default-box-shadow;
    }

	&__drop {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 100;
        min-width: 160px;
        max-width: 260px;
		transform: translateY(50px) scale(0.9);
		@include transition();
        
        &--arrow {
            &::before {
                @include pseudo;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 6px 6px 6px;
                border-color: transparent transparent #fff transparent;
                top: -5px;
                right: 15px;
            }
        }
        
		&--right {
			right: 0;
			left: auto;
		}
	}

	&.is-dropdown-open & {
		&__drop {
			opacity: 1;
			visibility: visible;
            transform: translateY(15px) scale(1);
		}
	}
}

.dropdown-links {
    font-size: 15px;
    line-height: $small-line-height;
    
    &__list {
        padding: 15px 22px;
    }
    
    &__item {
        
        /*& + & {
            border-top: 1px #f0f0f0 solid;
        }*/
        
        a {
            @extend .flex, .flex--center-y, .flex--nowrap;
            color: $default-font-color;
            padding: 5px 0;
            display: block;
            
            @include hover() {
                color: $key-color-1;	
            }
            
            [class*="ico-"] {
                margin-right: 10px;
                font-size: 15px;
            }
        }
        
        &--logout { 
            
        }
    }
    
    &__item:last-child {
        border-bottom: none;
    }
}